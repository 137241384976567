import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/index"
import { Link } from 'gatsby'
import { IoIosArrowRoundForward } from "react-icons/io"; 
import { IoIosArrowRoundBack } from "react-icons/io"; 
import Img from "gatsby-image"
import DetailHeader from '../components/detailHeader/detailHeader'
import Seo from "../components/seo/seo"

export default class BlogList extends React.Component {
  render() {
  const { currentPage, numPages } = this.props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  const posts = this.props.data.allNodeBlog.edges
  const url = this.props.location.href;
  
    return (
      <Layout>
      <Seo title="Blog" url={url}/>
      <DetailHeader title="Blog"/>
      <div className="wd-blog wd-blog--color">
        <div className="wd-blog__container wd-blog__container--display wd-blog__container--padding wd-blog__container--color">
          {posts.map(({ node }) => {
            return(
              
            <div className="wd-blog__blog wd-blog__blog--color wd-blog__blog--margin">
            
              {node.relationships.field_img.relationships.field_media_image != null &&
                  <Link to={node.path.alias + '/'}>
                    <Img className="wd-blog__img" fixed={node.relationships.field_img.relationships.field_media_image.localFile.childImageSharp.fixed} />
                  </Link>
              }
              <span className="wd-blog__term wd-blog__term--color">{node.relationships.field_blog_type.name}</span>
              <h2 className="wd-blog__title wd-blog__title--color"><Link to={node.path.alias + '/'}>{node.title}</Link></h2>
              <div className="wd-blog__description wd-blog__description--color" dangerouslySetInnerHTML={{ __html: node.body.summary }} />
              <Link to={ node.path.alias + '/'} className=" wd-blog__link wd-blog__link--color wd-button wd-button--3">
                  Lire plus <IoIosArrowRoundForward className="wd-button__icon wd-button__icon--1"/> 
              </Link>
            </div>  
          
          )
          })} 
          </div>
          {!isFirst && (
          <div className="wd-box">
            <Link className="wd-button wd-button--1" to={"blog/" + prevPage} rel="prev">
            <IoIosArrowRoundBack className="wd-button__icon wd-button__icon--3"/> Précédent
            </Link>
          </div>
        )}
        
        {!isLast && (
          <div className="wd-box">
            <Link className="wd-button wd-button--1" to={"blog/" + nextPage} rel="next">
              Suivant <IoIosArrowRoundForward className="wd-button__icon wd-button__icon--1"/> 
            </Link>
          </div>
        )} 
        
      </div>

        
 
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allNodeBlog(
      limit: $limit
      skip: $skip
      sort: {order : DESC, fields : created}
    ) {
      edges {
        node {
          uuid
          nid
          title
          path {
            alias
          }
          body {
            value
            format
            processed
            summary
          }
          relationships {
            field_blog_type {
              name
            }
          }
          relationships {
            field_img {
              relationships {
                field_media_image {
                  localFile {
                    childImageSharp {
                      fixed(width:362, height:200,quality: 90) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`